export default
{
    welcome: "欢迎",
    home:"主页",
    blockchain:"链上信息",
    masternode: "主节点",
    assets:"资产",

    block:"区块",

}