export default  
{
    welcome: "Welcome",
    home:"Home",
    blockchain:"Block Chain",
    masternode: "Master Node",
    assets:"Assets",

    block:"Block",

}